import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class FallbackableImage extends PureComponent {
	static propTypes = {
		fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	};

	state = {
		error: false,
	};

	onError = () => {
		this.setState({ error: true });
	};

	render() {
		const props = Object.assign({}, this.props);
		if ((this.state.error || !props.src) && props.fallback) {
			if (typeof props.fallback === 'string') {
				props.src = props.fallback;
			} else if (typeof props.fallback === 'function') {
				return props.fallback();
			}
		}

		delete props.fallback;
		return <img {...props} onError={this.onError} />;
	}
}
